<style scoped>
.notification {
	background-color: transparent;
}
</style>

<script>
export default {
	name: 'LoginLogoff',
	data() {
		return {
			loading: true,
		};
	},
	beforeCreate() {
		setTimeout(async () => {
			await this.$store.dispatch('sessao/logoff');
			this.$router.go({ name: 'login.home' });
		}, 2000);
	},
	methods: {
		goto() {
			this.$router.push({ name: 'home' });
		},
	},
};
</script>

<template>
	<div class="container">
		<div class="columns">
			<b-notification
				:closable="false"
				class="notification"
			>
				<b-loading
					v-model="loading"
					:is-full-page="true"
					:can-cancel="false"
				/>
			</b-notification>
		</div>
	</div>
</template>
